import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import "./AdminLogin.css"; // Import the CSS file for styling

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loginSuccess, setLoginSuccess] = useState(false); // Track login success
  const navigate = useNavigate();
  const location = useLocation(); // To retrieve where the user was trying to go

  const auth = getAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);

      setLoginSuccess(true); // Set login success
      setError(""); // Clear any previous errors

      // Redirect the admin to where they wanted to go or show a success message
      const redirectTo = location.state?.from?.pathname || "/"; // Default to home or a specific route
      setTimeout(() => navigate(redirectTo), 2000); // Redirect after showing success message
    } catch (error) {
      setError("Invalid email or password");
      setLoginSuccess(false); // Reset success
    }
  };

  return (
    <div className="login-container">
      <h2>Admin Login</h2>
      {loginSuccess ? (
        <p className="success-message">Login successful! Redirecting...</p> // Show success message
      ) : (
        <form onSubmit={handleLogin}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            required
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <button type="submit">Login</button>
        </form>
      )}

      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Login;
