import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import GiftForm from "./components/GiftForm";
import GiftCard from "./components/GiftCard";
import AdminGiftCard from "./components/AdminGiftCard";
import Login from "./components/AdminLogin";
import Header from "./components/Header";

// Helper component to check if user is an admin
const PrivateRoute = ({ children }) => {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  // List of admin emails
  const adminEmails = ["fatima@admin.com"];

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user); // Set the current user
      } else {
        setUser(null); // If no user is logged in
      }
      setLoading(false); // Loading complete
    });

    return () => unsubscribe();
  }, [auth]);

  if (loading) {
    return <p>Loading...</p>; // Display loading indicator while checking
  }

  // If no user is logged in, redirect to login page
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // Check if the logged-in user's email matches any of the admin emails
  const isAdmin = adminEmails.includes(user.email);

  // If the user is not an admin
  if (!isAdmin) {
    return <p>Access Denied. Admins only.</p>;
  }

  // If the user is an admin, allow access to the page
  return children;
};

const App = () => {
  useEffect(() => {
    // Function to adjust the padding of the body dynamically
    const adjustBodyPadding = () => {
      const header = document.querySelector(".header");
      if (header) {
        const headerHeight = header.offsetHeight;
        document.body.style.paddingTop = `${headerHeight}px`;
      }
    };

    // Adjust padding on load
    adjustBodyPadding();

    // Adjust padding if the window is resized
    window.addEventListener("resize", adjustBodyPadding);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", adjustBodyPadding);
    };
  }, []);
  return (
    <Router>
      <Header />
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<GiftForm />} />
        <Route path="/giftcard/:id" element={<GiftCard />} />

        {/* Admin Route */}
        <Route
          path="/admin/giftcard/:id"
          element={
            <PrivateRoute>
              <AdminGiftCard />
            </PrivateRoute>
          }
        />

        {/* Login Route */}
        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
};

export default App;
