import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import "./GiftCard.css";
import image1 from "../assets/image1.jpeg";
import image2 from "../assets/image2.jpeg";
import logo from "../assets/logo.png"; // Assuming you have a logo

const GiftCard = () => {
  const { id } = useParams(); // Get the card ID from the URL
  const [giftData, setGiftData] = useState(null);
  const [giftOpened, setGiftOpened] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchGiftCard = async () => {
      try {
        const docRef = doc(db, "cards", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setGiftData(docSnap.data());
        } else {
          console.log("No such card!");
        }
      } catch (error) {
        console.error("Error fetching card:", error);
      } finally {
        setLoading(false); // Set loading to false once the data is fetched
      }
    };

    fetchGiftCard();
  }, [id]);

  if (loading) return <p>טוען...</p>;

  if (!giftData) return <p>לא נמצאה כרטיס מתנה!</p>;

  const { recipientName, message, senderName, giftType, card_status } =
    giftData;
  const giftImage = giftType === "makeup" ? image1 : image2;

  // Check if the card is not available
  if (!card_status) {
    return <p>כרטיס מתנה זה אינו זמין יותר.</p>;
  }

  const handleOpenGift = () => {
    setGiftOpened(true);
  };

  const handleCloseGift = () => {
    setGiftOpened(false); // Close the gift modal
  };

  return (
    <div className="gift-card-container">
      <div className={`gift-card ${giftOpened ? "open-animation" : ""}`}>
        <div className="card-content">
          <img
            src={logo}
            alt="Fatima Makeup Artist Logo"
            className="logo right-logo"
          />
          <div className="message-content" style={{ direction: "rtl" }}>
            <h2>{recipientName}, קיבלת מתנה!</h2>
            <p>{message}</p>
            <p>מאת: {senderName}</p>
          </div>
        </div>
        <button onClick={handleOpenGift} className="open-gift-btn">
          פתח מתנה
        </button>
      </div>

      {giftOpened && (
        <div className="gift-reveal">
          <div className="gift-box animated-reveal">
            <h3>המתנה שלך:</h3>
            <img src={giftImage} alt="Gift" className="gift-image" />
            <p>{giftType === "makeup" ? "איפור" : "סדנת איפור"}</p>
            <button onClick={handleCloseGift} className="close-gift-btn">
              סגור מתנה
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GiftCard;
