// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth"; // Optional: if using Firebase Auth

// Your web app's Firebase configuration (you can find this in Firebase Console)
const firebaseConfig = {
    apiKey: "AIzaSyDWgCND7FuBlqW4YBDSwBwHvrzyZFDDC5Q",
    authDomain: "gift-card-45c0b.firebaseapp.com",
    projectId: "gift-card-45c0b",
    storageBucket: "gift-card-45c0b.appspot.com",
    messagingSenderId: "501298791790",
    appId: "1:501298791790:web:9f3969476c1f2063982d7f",
    measurementId: "G-GCVFW4QSCW"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Optional: Initialize Firebase Auth if you are using it
const auth = getAuth(app);

export { db, auth };