import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import { db } from "../firebase";
import "./AdminGiftCard.css";

const AdminGiftCard = () => {
  const { id } = useParams();
  const [giftData, setGiftData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Added error state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGiftCard = async () => {
      try {
        const docRef = doc(db, "cards", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setGiftData(docSnap.data());
        } else {
          console.log("No such card!");
          setError("כרטיס מתנה לא נמצא"); // Error message in Hebrew
        }
      } catch (err) {
        console.error("Error fetching card:", err);
        setError("שגיאה בטעינת הכרטיס"); // Error message in Hebrew
      } finally {
        setLoading(false); // Loading state handled
      }
    };

    fetchGiftCard();
  }, [id]);

  const toggleCardStatus = async () => {
    if (giftData) {
      const cardRef = doc(db, "cards", id);
      const newStatus = !giftData.card_status;

      // Update the card status in Firestore
      await updateDoc(cardRef, { card_status: newStatus });

      // Update the state locally
      setGiftData((prevData) => ({ ...prevData, card_status: newStatus }));
    }
  };

  // Logout function for admin
  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // After logout, redirect to login page
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error logging out:", error);
      });
  };

  if (loading) return <p>טוען...</p>; // "Loading..." in Hebrew
  if (error) return <p>{error}</p>; // Display any error that occurred

  // Destructuring the necessary fields from the Firestore document
  const {
    recipientName,
    recipientPhone,
    senderName,
    senderPhone,
    message,
    giftType,
    card_status,
  } = giftData;

  return (
    <div className="gift-card-container">
      {/* Logout button */}
      <button onClick={handleLogout} className="logout-button">
        התנתקות
      </button>

      <div className="gift-card">
        <h2>פרטי כרטיס מתנה (תצוגת מנהל)</h2>

        {/* Displaying the information */}
        <p>
          <strong>שם המקבל:</strong> {recipientName}
        </p>
        <p>
          <strong>טלפון של המקבל:</strong> {recipientPhone}
        </p>
        <p>
          <strong>שם השולח:</strong> {senderName}
        </p>
        <p>
          <strong>טלפון של השולח:</strong> {senderPhone}
        </p>
        <p>
          <strong>הודעה:</strong> {message}
        </p>
        <p>
          <strong>סוג מתנה:</strong> {giftType === "makeup" ? "איפור" : "סדנה"}
        </p>
        <p>
          <strong>סטטוס הכרטיס:</strong> {card_status ? "פעיל" : "לא פעיל"}
        </p>

        {/* Button to toggle the status of the card */}
        <button
          onClick={toggleCardStatus}
          className={card_status ? "deactivate" : "activate"}
        >
          {card_status ? "השבת כרטיס" : "הפעל כרטיס"}
        </button>
      </div>
    </div>
  );
};

export default AdminGiftCard;
