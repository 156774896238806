import React from "react";
import "./Header.css"; // Import the CSS file
import { FaInstagram } from "react-icons/fa"; // Import Instagram icon from react-icons

const Header = () => {
  return (
    <header className="header">
      <a
        href="https://www.instagram.com/fatimahresh.makeupartist/" // Update with your actual Instagram link
        target="_blank"
        rel="noopener noreferrer"
        className="instagram-link"
        aria-label="Instagram"
      >
        <FaInstagram className="instagram-icon" />
      </a>

      <h1 className="logo-text">Fatima Makeup Artist</h1>
    </header>
  );
};

export default Header;
