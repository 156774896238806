import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";
import emailjs from "emailjs-com";
import "./GiftForm.css";
import image1 from "../assets/image1.jpeg";
import image2 from "../assets/image2.jpeg";
import logo from "../assets/logo.png"; // Assuming the logo file is located correctly

const GiftForm = () => {
  const [formData, setFormData] = useState({
    senderName: "",
    senderPhone: "",
    recipientName: "",
    recipientPhone: "",
    message: "",
    giftType: "makeup",
    card_status: true,
  });

  const [toastVisible, setToastVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleGiftSelect = (giftType) => {
    setFormData((prev) => ({ ...prev, giftType }));
  };

  const sendEmail = async (formData, giftCardId) => {
    const templateParams = {
      senderName: formData.senderName,
      senderPhone: formData.senderPhone,
      recipientName: formData.recipientName,
      recipientPhone: formData.recipientPhone,
      message: formData.message,
      giftType: formData.giftType === "makeup" ? "Makeup" : "Workshop",
      giftCardId: giftCardId,
    };

    try {
      await emailjs.send(
        "service_1uzc453",
        "template_zrsc3xn",
        templateParams,
        "RYtlQfjc5XdyPbldm"
      );
      console.log("Email successfully sent!");
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const docRef = await addDoc(collection(db, "cards"), formData);
      console.log("Document written with ID: ", docRef.id);

      await sendEmail(formData, docRef.id);

      setToastVisible(true);
      setFormData({
        senderName: "",
        senderPhone: "",
        recipientName: "",
        recipientPhone: "",
        message: "",
        giftType: "makeup",
        card_status: true,
      });

      setTimeout(() => {
        setToastVisible(false);
      }, 3000);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div className="gift-form-container">
      {toastVisible && (
        <div className="toast">
          <p>הבקשה נשלחה בהצלחה!</p>
        </div>
      )}

      <form onSubmit={handleSubmit} className="gift-form">
        <div className="logo-container">
          <img src={logo} alt="Fatima Makeup Artist Logo" className="logo" />
        </div>
        <label>
          שם השולח:
          <input
            type="text"
            name="senderName"
            value={formData.senderName}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          מספר טלפון של השולח:
          <input
            type="tel"
            name="senderPhone"
            value={formData.senderPhone}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          שם המקבל:
          <input
            type="text"
            name="recipientName"
            value={formData.recipientName}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          מספר טלפון של המקבל:
          <input
            type="tel"
            name="recipientPhone"
            value={formData.recipientPhone}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          הודעה:
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </label>

        <div className="gift-select">
          <div
            className={`gift-option ${
              formData.giftType === "makeup" ? "selected" : ""
            }`}
            onClick={() => handleGiftSelect("makeup")}
          >
            <img src={image1} alt="Makeup" />
            <p>איפור (250 שקל)</p>
          </div>

          <div
            className={`gift-option ${
              formData.giftType === "workshop" ? "selected" : ""
            }`}
            onClick={() => handleGiftSelect("workshop")}
          >
            <img src={image2} alt="Workshop" />
            <p>סדנת איפור (300 שקל)</p>
          </div>
        </div>
        <button type="submit">שלח</button>
      </form>
    </div>
  );
};

export default GiftForm;
